import { fetchProductDetails } from 'actions/Products';
import classNames from 'classnames';
import { Button, ButtonRow, SafeHtml } from 'common';
import VimeoVideo from 'common/components/VimeoVideo/VimeoVideo';
import ProductFilterIcon from 'components/Product/ProductFilterIcon';
import Alert from 'components/UI/Alert';
import Icon from 'components/UI/Icon';
import { navigateBack } from 'navigation';
import { AddToCartButton, ProductDetailsAccordions, RelatedProducts } from 'product';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { NavLink, withRouter } from 'react-router-dom';
import { AddToSubscriptionButton } from 'subscription';
import { isPaused } from 'utilities/subscription';
import './ProductLarge.scss';
import { useIsSoldOut } from '../../hooks';
import ProductDescription from './ProductDescription';
const closeIcon = require('img/cancel.svg');
const MAX_NUMBER_OF_RECOMMENDATIONS = 3;
const NUMBER_FOR_GROUP_ALERT_SUGGESTION = 3;
function ProductLarge({ details, detailsLoaded, handleClose, isLoggedIn, isMobile, isSubscriptionActive, producer, product, productCount, productId, region, supplierWithDescription, }) {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const soldOut = useIsSoldOut(product);
    useEffect(() => {
        if (region && product && !detailsLoaded) {
            dispatch(fetchProductDetails(productId, region.slug));
        }
    }, [productId, region, detailsLoaded, product, dispatch]);
    const renderRecommendations = () => (React.createElement("div", { className: "product__recommendations" },
        React.createElement("h2", null, "Folk som kj\u00F8pte dette kj\u00F8pte ogs\u00E5"),
        React.createElement(RelatedProducts, { productIds: [productId], regionId: region.id, limit: MAX_NUMBER_OF_RECOMMENDATIONS })));
    // Product not found
    if (!product) {
        return (React.createElement("div", { className: "product product__not_found" },
            React.createElement("div", { className: "product__content" },
                React.createElement("div", { className: "product__content__not-found" },
                    React.createElement("p", null, "Vi beklager, dette produktet er ikke tilgjengelig.")))));
    }
    const showSubscription = product.subscriptionProduct && isLoggedIn && isSubscriptionActive;
    const filterTags = product.filterTags.filter((tag) => tag.image != null);
    const addContainerClasses = classNames({
        'product__content__header__add-buttons-container': true,
        'product__content__header__add-buttons-container__subscription-on': showSubscription,
    });
    return (React.createElement("div", { className: "product" },
        React.createElement("div", { className: "product__top-container" },
            ((_a = product.badge) === null || _a === void 0 ? void 0 : _a.image) && (React.createElement("div", { className: "product__top-container__badge-container" },
                React.createElement("img", { className: "product__top-container__badge-container__badge", src: product.badge.image, alt: product.badge.name }))),
            handleClose && (React.createElement("div", { className: "product__top-container__close-button-wrapper" },
                React.createElement("button", { className: "product__top-container__close-button-wrapper__button", "aria-label": "Lukk vinduet", onClick: (e) => {
                        handleClose(e);
                    } },
                    React.createElement(Icon, { svg: closeIcon }))))),
        React.createElement("div", { className: "product__image-container" },
            React.createElement("div", { className: "product__image" },
                React.createElement("picture", null,
                    React.createElement("source", { type: "image/webp", srcSet: `${product.productPageImage.webp_800x480} 400w, ${product.productPageImage.webp_1600x960} 800w` }),
                    React.createElement("source", { type: "image/png", srcSet: `${product.productPageImage.medium} 400w, ${product.productPageImage.large} 800w` }),
                    React.createElement("img", { src: product.productPageImage.large, alt: product.title }))),
            filterTags.length > 0 && (React.createElement("div", { className: "product__image-container__taglist" }, filterTags.map((f) => (React.createElement("div", { key: f.name, className: "product__image-container__taglist__tag" },
                React.createElement(ProductFilterIcon, { key: f.name, name: f.name, url: f.image, style: "large" }))))))),
        React.createElement("div", { className: "product__content" },
            React.createElement("div", { className: "product__content__header" },
                React.createElement("div", null,
                    React.createElement("h1", { className: "product__content__header__title", id: "product-content-title" }, product.title),
                    React.createElement("span", { className: "product__content__header__vendor" },
                        "fra",
                        ' ',
                        producer ? (React.createElement(NavLink, { className: "product__content__header__vendor--producer", to: `/produsent/${producer.slug}` }, producer.name)) : (React.createElement(React.Fragment, null, product.vendor)))),
                React.createElement("div", { className: addContainerClasses },
                    soldOut && (React.createElement("div", { className: "product__soldOut" },
                        ' ',
                        "Vi er dessverre utsolgt for denne varen.",
                        ' ')),
                    !soldOut && (React.createElement(AddToCartButton, { product: product, shrinkOnMobile: false, soldOut: soldOut })),
                    (showSubscription && !soldOut) && (React.createElement(AddToSubscriptionButton, { product: product, productDetails: true })),
                    productCount > 0 && (React.createElement(ButtonRow, { display: isMobile ? 'center' : 'stretch' },
                        React.createElement(Button, { type: "action", style: "primary", onClick: () => navigateBack(dispatch, history, location) }, "Fortsett \u00E5 handle"),
                        React.createElement(Button, { type: "action", style: "secondary", onClick: () => history.push('/checkout') }, "G\u00E5 til kassen")))),
                productCount >= NUMBER_FOR_GROUP_ALERT_SUGGESTION && (React.createElement("div", { className: "product__content__header__alert" },
                    React.createElement(Alert, { type: 'success' },
                        React.createElement("p", { className: "product__content__header__alert-text" },
                            React.createElement("strong", null, "Skal du sende det samme til flere?")),
                        React.createElement("p", { className: "product__content__header__alert-text" }, "Legg til det du \u00F8nsker \u00E5 bestille til \u00E9n person. Vi sender den samme handlekurven til hele gjengen. Du kan legge til mottakere i kassen."))))),
            React.createElement(ProductDescription, { description: product.description, useTruncation: isMobile }),
            product.vimeoLink != null && React.createElement(VimeoVideo, { url: product.vimeoLink }),
            React.createElement(ProductDetailsAccordions, { details: details, loading: !detailsLoaded }),
            renderRecommendations(),
            producer && (React.createElement("div", { className: "product__content__producer" },
                React.createElement("div", { className: "product__content__producer__heading" },
                    ((_b = producer.logo) === null || _b === void 0 ? void 0 : _b.small) && (React.createElement("img", { className: "product__content__producer__heading__logo", src: producer.logo.small, alt: "" })),
                    React.createElement("h2", { className: "product__content__producer__heading__text" },
                        "Om",
                        ' ',
                        React.createElement(NavLink, { className: "product__content__header__vendor--producer", to: `/produsent/${(_c = product.producer) === null || _c === void 0 ? void 0 : _c.slug}` }, (_d = product.producer) === null || _d === void 0 ? void 0 : _d.name))),
                React.createElement(SafeHtml, { type: "p", html: producer.description }))),
            !producer && (supplierWithDescription === null || supplierWithDescription === void 0 ? void 0 : supplierWithDescription.description) && (React.createElement("div", { className: "product__content__producer" },
                React.createElement("h2", null,
                    "Om ",
                    supplierWithDescription.name),
                React.createElement("p", null, supplierWithDescription.description))),
            isMobile && (React.createElement(ButtonRow, { display: "center" },
                React.createElement(Button, { type: "action", style: "ghost", onClick: () => navigateBack(dispatch, history, location) }, "Tilbake til nettbutikken"))))));
}
function mapStateToProps({ cart, products, region, user, ui, subscription }, ownProps) {
    const productId = ownProps.match.params.productId;
    const productWithDetails = products.productsWithDetails[productId];
    const detailsLoaded = !!productWithDetails;
    const details = productWithDetails === null || productWithDetails === void 0 ? void 0 : productWithDetails.additionalInformation;
    const product = productWithDetails !== null && productWithDetails !== void 0 ? productWithDetails : products.products.find((p) => p.id === productId);
    const supplier = (product === null || product === void 0 ? void 0 : product.supplier) && products.suppliers.find((s) => s.id === product.supplier.id);
    const producer = product === null || product === void 0 ? void 0 : product.producer;
    const cartProduct = cart.products.find((p) => p.id == product.id);
    const productCount = (cartProduct === null || cartProduct === void 0 ? void 0 : cartProduct.quantity) || 0;
    return {
        details,
        detailsLoaded,
        handleClose: ownProps.handleClose,
        isLoggedIn: user.isLoggedIn,
        isMobile: ui.isMobile,
        isSubscriptionActive: !isPaused(subscription.stopDates) && !subscription.stopped,
        producer: producer,
        product,
        productCount,
        productId,
        region: region,
        supplierWithDescription: supplier,
    };
}
export default withRouter(connect(mapStateToProps)(ProductLarge));
