import { useSelector } from 'react-redux';
import { ProductContext } from 'product';
import React, { useContext } from 'react';
import ItemCarouselHeading from 'components/ItemCarouselHeading/ItemCarouselHeading';
import BundleProductGrid from '../../../product/components/BundleProductGrid/BundleProductGrid';
import BundleProductCarousel from '../../../product/components/BundleProductCarousel/BundleProductCarousel';
function EditorialBundleProducts({ title, subtitle, mobileSubtitle, displayProducts, leadText, bundles, }) {
    const isMobile = useSelector(({ ui }) => ui.isMobile);
    const { context: parentContext } = useContext(ProductContext);
    const slicedBundleProducts = bundles.slice(0, displayProducts);
    return (React.createElement(ProductContext.Provider, { value: { parentContext, context: 'bundle' } },
        React.createElement("div", { className: "editorial-products" },
            React.createElement(ItemCarouselHeading, { title: title, subtitle: isMobile ? mobileSubtitle : subtitle }),
            !isMobile && leadText && (React.createElement("p", { className: "editorial-products__lead-text" }, leadText)),
            isMobile ? (React.createElement(BundleProductCarousel, { bundles: slicedBundleProducts })) : (React.createElement(BundleProductGrid, { bundles: slicedBundleProducts })))));
}
export default EditorialBundleProducts;
