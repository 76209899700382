import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import { v4 as uuid } from 'uuid';
const baseState = {
    products: [],
    discount: 0,
    freight: {
        amount: 0,
        vatRate: 0,
        orderTotalLeftUntilFreeFreight: 0,
        freightReason: 'DEFAULT',
        externalId: undefined,
    },
    total: 0,
    totalWithoutFreight: 0,
    hasEnoughQuantity: false,
    hasDisabledProductsForRegion: false,
    id: uuid(),
    pendingCheckCart: false,
};
export function cartReducer(state = Object.assign({}, baseState), action) {
    let products = [...state.products];
    switch (action.type) {
        case 'ADD_TO_CART':
            products = addOrIncreaseQuantity(products, action.payload.product);
            return Object.assign(Object.assign({}, state), { products: products, total: calculateTotal(products, state.discount, state.freight.amount), totalWithoutFreight: calculateTotalForFreight(products), id: uuid() });
        case 'REMOVE_FROM_CART':
            products = removeOrReduceQuantity(products, action.payload);
            return Object.assign(Object.assign({}, state), { products: products, total: calculateTotal(products, state.discount, state.freight.amount), totalWithoutFreight: calculateTotalForFreight(products), id: uuid() });
        case 'UPDATE_CART_QUANTITY':
            products = updateQuantity(products, action.payload.quantity, action.payload.product);
            return Object.assign(Object.assign({}, state), { products: products, total: calculateTotal(products, state.discount, state.freight.amount), totalWithoutFreight: calculateTotalForFreight(products), id: uuid() });
        case 'FETCH_CHECK_CART':
        case 'FETCH_CHECK_CART_REJECTED':
            products.forEach((p) => {
                delete p.quantityPossible;
            });
            return Object.assign(Object.assign({}, state), { products });
        case 'FETCH_CHECK_CART_FULLFILLED':
            const response = action.payload;
            products.map((p) => {
                const responseProduct = find(response.products, ['id', p.id]);
                const quantityPossible = responseProduct && responseProduct.quantityPossible;
                const disabledForRegion = responseProduct && responseProduct.disabledForRegion;
                p.quantityPossible = quantityPossible;
                p.disabledForRegion = disabledForRegion;
                return p;
            });
            return Object.assign(Object.assign({}, state), { products, hasEnoughQuantity: response.hasEnoughQuantity, hasDisabledProductsForRegion: response.hasDisabledProductsForRegion });
        case 'CHECK_CART_SUCCESS':
            const discount = action.payload.discountedAmount;
            return Object.assign(Object.assign({}, state), { discount: discount, pendingCheckCart: false, total: calculateTotal(products, discount, state.freight.amount) });
        case 'VALIDATE_COUPON_CODE_FAILED':
            return Object.assign(Object.assign({}, state), { discount: 0, total: calculateTotal(products, 0, state.freight.amount) });
        case 'GET_FREIGHT_SUCCESS':
            return Object.assign(Object.assign({}, state), { freight: {
                    amount: action.payload.amount,
                    vatRate: action.payload.vatRate,
                    orderTotalLeftUntilFreeFreight: action.payload.orderTotalLeftUntilFreeFreight,
                    freightReason: action.payload.freightReason,
                    externalId: action.payload.externalId,
                }, total: calculateTotal(products, state.discount, action.payload.amount) });
        case 'GET_FREIGHT_FAILED':
            return Object.assign(Object.assign({}, state), { freight: Object.assign({}, baseState.freight), total: calculateTotal(products, state.discount, 0) });
        case 'CHECKOUT_COMPLETED':
            return Object.assign(Object.assign({}, state), { products: [], id: uuid() });
        case 'REFRESH_CART':
            return Object.assign(Object.assign({}, state), { id: uuid() });
        case 'PLACE_ORDER_SUCCESS':
            return Object.assign(Object.assign({}, state), { products: [], total: 0, discount: 0 });
        case '2_PHASE_COMPLETE':
            return Object.assign(Object.assign({}, state), { id: action.payload || state.id, products: [], total: 0, discount: 0 });
        case 'USER_LOGOUT':
            return Object.assign({}, baseState);
        case 'PENDING_CHECK_CART':
            return Object.assign(Object.assign({}, state), { pendingCheckCart: true });
        case 'FETCH_ALL_PRODUCTS_SUCCESS':
            const newProducts = action.payload;
            const updatedCartProducts = state.products.map((product) => {
                const newProduct = newProducts.find((p) => p.id === product.id);
                if (newProduct) {
                    return Object.assign(Object.assign({}, product), newProduct);
                }
                else {
                    return product;
                }
            });
            return Object.assign(Object.assign({}, state), { products: updatedCartProducts });
        default:
            return state;
    }
}
function calculateTotal(products, discount, freightAmount) {
    if (products.length === 0)
        return 0;
    const total = products
        .map((product) => product.price * product.quantity)
        .reduce((acc, curr) => acc + curr) -
        discount +
        freightAmount;
    return Number(total.toFixed(2));
}
function calculateTotalForFreight(products) {
    if (products.length === 0)
        return 0;
    const total = products
        .map((product) => product.price * product.quantity)
        .reduce((acc, curr) => acc + curr);
    return Number(total.toFixed(2));
}
function addOrIncreaseQuantity(products, product) {
    const index = findIndex(products, (item) => item.id === product.id);
    if (index >= 0) {
        products[index].quantity = products[index].quantity + 1;
    }
    else {
        products.push(Object.assign(Object.assign({}, product), { quantity: 1 }));
    }
    return products;
}
function removeOrReduceQuantity(products, product) {
    const index = findIndex(products, (item) => item.id === product.id);
    if (products[index].quantity > 1) {
        products[index].quantity = products[index].quantity - 1;
    }
    else {
        remove(products, (item) => item.id === product.id);
    }
    return products;
}
function updateQuantity(products, quantity, product) {
    const index = findIndex(products, (item) => item.id === product.id);
    if (quantity > 0) {
        products[index].quantity = quantity;
    }
    else {
        remove(products, (item) => item.id === product.id);
    }
    return products;
}
