import { useSelector } from 'react-redux';
import { FEATURES, isFeatureEnabled } from 'common';
import { useEffect, useState } from 'react';
import { isProductSoldOut } from './util';
export function useIsSoldOut(product) {
    const stock = useSelector((state) => state.products.stock);
    const deliveryDate = useSelector((state) => state.checkout.deliveryDate);
    const isStoreEnabled = useSelector(({ features }) => isFeatureEnabled(features.enabled, FEATURES.STORE));
    const [isSoldOut, setSoldOut] = useState(false);
    useEffect(() => {
        const isStoreEnabledProductSoldOut = (product) => {
            return product.availability.length == 1
                ? !product.availability.some((a) => a.quantity > 0)
                : !product.availability
                    .filter((a) => a.date == deliveryDate)
                    .some((a) => a.quantity > 0);
        };
        if (!product)
            return;
        const products = Array.isArray(product) ? product : [product];
        if (isStoreEnabled) {
            setSoldOut(products.some(p => isStoreEnabledProductSoldOut(p)));
        }
        else {
            setSoldOut(products.some(p => isProductSoldOut(stock, p)));
        }
    }, [stock, product, deliveryDate, isStoreEnabled]);
    return isSoldOut;
}
