import axios from 'axios'
import { getNewestMatchingPaymentMethod } from 'utilities/paymentMethod'
import { submit3DSecureChallengeForm } from 'actions/SecureChallenge'
import { handleNetworkError } from 'actions/Error'

export function getPaymentMethods() {
    return (dispatch) => {
        dispatch({ type: 'FETCH_PAYMENT_METHODS', payload: [] })
        axios
            .get(`${process.env.CORE_API}/paymentmethod/all`)
            .then((response) => {
                dispatch({
                    type: 'FETCH_PAYMENT_METHODS_SUCCESS',
                    payload: response.data,
                })
            })
            .catch((err) => {
                console.error(err)
                dispatch({
                    type: 'FETCH_PAYMENT_METHODS_FAILED',
                    payload: err,
                })
                dispatch(handleNetworkError(err))
            })
    }
}

export function deletePaymentMethod(paymentMethodId) {
    return (dispatch) => {
        dispatch({
            type: 'DELETE_PAYMENT_METHOD',
            payload: paymentMethodId,
        })
        axios
            .delete(`${process.env.CORE_API}/paymentmethod/${paymentMethodId}`)
            .then((response) => {
                dispatch({
                    type: 'DELETE_PAYMENT_METHOD_SUCCESS',
                    payload: response.data,
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'DELETE_PAYMENT_METHOD_FAILED',
                    payload: err,
                })
                dispatch(handleNetworkError(err))
            })
    }
}

export function abortDeletePaymentMethod(paymentMethodId) {
    return (dispatch) => {
        dispatch({
            type: 'ABORT_DELETE_PAYMENT_METHOD',
            payload: paymentMethodId,
        })
        axios
            .put(`${process.env.CORE_API}/paymentmethod/${paymentMethodId}`)
            .then((response) => {
                dispatch({
                    type: 'ABORT_DELETE_PAYMENT_METHOD_SUCCESS',
                    payload: response.data,
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'ABORT_DELETE_PAYMENT_METHOD_FAILED',
                    payload: err,
                })
                dispatch(handleNetworkError(err))
            })
    }
}

export function fetchSubscriptionPaymentMethod() {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_SUBSCRIPTION_PAYMENT_METHOD',
        })
        axios
            .get(`${process.env.CORE_API}/v2/paymentmethod/subscription`)
            .then((response) => {
                dispatch({
                    type: 'FETCH_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS',
                    payload: response.data,
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'FETCH_SUBSCRIPTION_PAYMENT_METHOD_FAILED',
                    payload: err,
                })
            })
    }
}

export function setPreferredPaymentMethod(paymentMethodId) {
    return (dispatch) => {
        dispatch({
            type: 'SET_PREFERRED_PAYMENT_METHOD',
            payload: paymentMethodId,
        })
        axios
            .put(`${process.env.CORE_API}/paymentmethod/preferred`, {
                paymentMethodId: paymentMethodId,
            })
            .then((response) => {
                dispatch(getPaymentMethods())
                dispatch({
                    type: 'SET_PREFERRED_PAYMENT_METHOD_SUCCESS',
                    payload: response.data,
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'SET_PREFERRED_PAYMENT_METHOD_FAILED',
                    payload: err,
                })
                dispatch(handleNetworkError(err))
            })
        axios.put(`${process.env.CORE_API}/v2/paymentmethod/${paymentMethodId}`, {
            useForSubscription: true,
        })
    }
}

function findCreatedPaymentMethod(response, cardNo) {
    const paymentMethods = response && response.data && response.data.storedPaymentMethods
    if (!paymentMethods || paymentMethods.length === 0) return null

    return getNewestMatchingPaymentMethod(paymentMethods, cardNo)
}

export function addPaymentMethod(cardNo, token, isPreferred, returnTo) {
    return (dispatch, getState) => {
        dispatch({
            type: 'ADD_PAYMENT_METHOD',
            payload: token,
        })
        axios
            .put(`${process.env.CORE_API}/paymentmethod`, {
                adyenCardToken: token,
                saveCardConsent: getState().newPaymentMethod.saveCardConsent,
            })
            .then((response) => {
                if (response.data.status === 'payment-method-challenge') {
                    submit3DSecureChallengeForm(response, returnTo)
                } else {
                    dispatch({
                        type: 'ADD_PAYMENT_METHOD_SUCCESS',
                        payload: response.data,
                    })
                }

                if (isPreferred) {
                    const paymentMethod = findCreatedPaymentMethod(response, cardNo)
                    if (paymentMethod) {
                        dispatch(setPreferredPaymentMethod(paymentMethod.id))
                    }
                } else {
                    dispatch(getPaymentMethods())
                }
            })
            .catch((err) => {
                dispatch({
                    type: 'ADD_PAYMENT_METHOD_FAILED',
                    payload: err,
                })
                dispatch(handleNetworkError(err))
            })
    }
}
