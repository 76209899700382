import { handleNetworkError } from 'actions/Error';
import axios from 'axios';
import { push, replace } from 'connected-react-router';
import kebabCase from 'lodash/kebabCase';
import { fetchProducers } from 'producer';
import { matchPath } from 'react-router';
import apiConfig from 'utilities/apiConfig';
import { FEATURES, isFeatureEnabled } from 'common';
import { checkCartCore } from '../../cart/actions/cartActions';
export function fetchAllProductData(region, regionId) {
    return (dispatch) => {
        dispatch(resetProductDetails());
        dispatch(fetchAllProducts(region, regionId));
        dispatch(fetchCategories(region));
        dispatch(fetchSuppliers(region));
        dispatch(fetchProducers(region));
        dispatch(fetchMyProducts());
    };
}
export function attemptFetchStock() {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_STOCK' });
        const state = getState();
        const deliveryDate = state.checkout.deliveryDate;
        const region = state.region.slug;
        if (!deliveryDate || !region) {
            return;
        }
        const params = { region, deliveryDate };
        axios
            .get(`${process.env.API_URL}/api/fetch-stock`, { params })
            .then((response) => {
            if (response.data.error) {
                dispatch({
                    type: 'FETCH_STOCK_FAILED',
                    payload: response.data.error,
                });
            }
            else {
                dispatch({
                    type: 'FETCH_STOCK_SUCCESS',
                    payload: response.data,
                });
            }
        })
            .catch((err) => {
            console.error(err);
            dispatch({ type: 'FETCH_STOCK_FAILED' });
        });
    };
}
function storeBadgeToBadge(badge) {
    if (badge) {
        return {
            name: badge.name,
            image: badge.imageUrl,
        };
    }
    return undefined;
}
function storeProductToProductWithDetails(p) {
    return {
        id: p.externalProductId,
        title: p.title,
        price: p.price,
        b2bPrice: p.price,
        soldOut: '0',
        vatRate: p.vatRate,
        currency: 'NOK',
        subscriptionProduct: p.availableForSubscription,
        b2bProduct: false,
        vendor: p.producer.name,
        description: p.description,
        link: '',
        images: [],
        filterTags: [],
        category: p.categories,
        supplier: {
            id: p.supplierId,
            name: p.producer.name,
            deadline: '21:00',
        },
        badge: storeBadgeToBadge(p.badge),
        isArtisanProduct: false,
        additionalInformation: p.additionalInformation,
        productImageType: 'transparent',
        navigationImage: {
            small: p.listImageUrl,
            medium: p.listImageUrl,
            large: p.listImageUrl,
            large_webp: p.listImageUrl,
            webp_400x400: p.listImageUrl,
            webp_600x600: p.listImageUrl,
            webp_800x800: p.listImageUrl,
        },
        productPageImage: {
            medium: p.detailImageUrl,
            large: p.detailImageUrl,
            webp_1600x960: p.detailImageUrl,
            webp_800x480: p.detailImageUrl,
        },
        productType: '',
        producer: {
            id: p.producer.id || '',
            name: p.producer.name,
            description: p.producer.description,
            slug: '',
        },
        sortingPriority: 0,
        weight: '0',
        vimeoLink: undefined,
        productContext: undefined,
        availability: p.availability,
    };
}
export function fetchAllProducts(region, regionId) {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_ALL_PRODUCTS' });
        if (isFeatureEnabled(getState().features.enabled, FEATURES.STORE)) {
            axios
                .get(`${process.env.CORE_API}/store/${regionId}`)
                .then((response) => {
                dispatch({
                    type: 'FETCH_ALL_PRODUCTS_SUCCESS',
                    payload: response.data.products.map((p) => storeProductToProductWithDetails(p)),
                });
                response.data.products.forEach((p) => {
                    dispatch({
                        type: 'FETCH_PRODUCT_DETAILS_SUCCESS',
                        payload: storeProductToProductWithDetails(p),
                    });
                });
                dispatch(checkCartCore());
            });
        }
        else {
            axios
                .get(`${process.env.API_URL}/${region}/api/v2/products`, apiConfig)
                .then((response) => {
                dispatch({
                    type: 'FETCH_ALL_PRODUCTS_SUCCESS',
                    payload: response.data.data,
                });
            })
                .catch((err) => {
                dispatch({ type: 'FETCH_ALL_PRODUCTS_FAILED', payload: err });
                if (!getState().products.products.length) {
                    dispatch(handleNetworkError(err));
                }
            });
        }
    };
}
export function fetchMyProducts() {
    return (dispatch, getState) => {
        if (!getState().user.isLoggedIn)
            return;
        dispatch({ type: 'FETCH_MY_PRODUCTS' });
        axios
            .get(`${process.env.CORE_API}/deliveries/ordered-count`)
            .then((response) => {
            dispatch({
                type: 'FETCH_MY_PRODUCTS_SUCCESS',
                payload: [...response.data]
                    .sort((a, b) => b.quantity - a.quantity)
                    .map((p) => p.id),
            });
        })
            .catch((err) => dispatch({ type: 'FETCH_MY_PRODUCTS_FAILED', payload: err }));
    };
}
export function fetchCategories(region) {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_CATEGORIES' });
        axios
            .get(`${process.env.API_URL}/${region}/api/v2/categories`, apiConfig)
            .then((response) => {
            dispatch({
                type: 'FETCH_CATEGORIES_SUCCESS',
                payload: response.data.data,
            });
        })
            .catch((err) => {
            dispatch({ type: 'FETCH_CATEGORIES_FAILED', payload: err });
            if (!getState().products.categories.length) {
                dispatch(handleNetworkError(err));
            }
        });
    };
}
export function fetchSuppliers(region) {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_SUPPLIERS' });
        axios
            .get(`${process.env.API_URL}/${region}/api/suppliers?includeDummy=0`, apiConfig)
            .then((response) => dispatch({
            type: 'FETCH_SUPPLIERS_SUCCESS',
            payload: response.data.data,
        }))
            .catch((err) => {
            dispatch({ type: 'FETCH_SUPPLIERS_FAILED', payload: err });
            if (!getState().products.suppliers.length) {
                dispatch(handleNetworkError(err));
            }
        });
    };
}
export function fetchProductDetails(id, region) {
    return (dispatch, getState) => {
        dispatch({ type: 'FETCH_PRODUCT_DETAILS' });
        axios
            .get(`${process.env.API_URL}/${region}/api/products/${id}`, apiConfig)
            .then((response) => {
            dispatch({
                type: 'FETCH_PRODUCT_DETAILS_SUCCESS',
                payload: response.data,
            });
        })
            .catch((err) => {
            dispatch({ type: 'FETCH_PRODUCT_DETAILS_FAILED', payload: err });
            if (!getState().products.productsWithDetails.id) {
                dispatch(handleNetworkError(err));
            }
        });
    };
}
export function resetFilterAndSort() {
    return { type: 'RESET_PRODUCT_FILTER_AND_SORT' };
}
export function setFilterTags(filterTags) {
    return { type: 'SET_PRODUCT_FILTER_TAGS', payload: filterTags };
}
export function setSortBy(orderBy) {
    return { type: 'SET_PRODUCT_SORT_BY', payload: orderBy };
}
export function resetProductDetails() {
    return { type: 'RESET_PRODUCT_DETAILS' };
}
export function gotoProduct(product, productContext) {
    const { id, title } = product;
    return (dispatch, getState) => {
        const productPath = { path: '/product/:productId' };
        const { pathname, state } = getState().router.location;
        const isModal = state && state.modal;
        const action = isModal && matchPath(pathname, productPath) ? replace : push;
        dispatch({ type: 'SELECT_ITEM', payload: { product, productContext } });
        dispatch(action({
            pathname: `/product/${id}/${kebabCase(title)}`,
            state: { modal: true, parentContext: productContext.context },
        }));
    };
}
