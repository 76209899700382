import { CartCounter, addToCart, removeFromCart } from 'cart';
import classNames from 'classnames';
import { FEATURES, isFeatureEnabled, Price } from 'common';
import { push } from 'connected-react-router';
import kebabCase from 'lodash/kebabCase';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './CartItem.scss';
import { hasAvailability } from '../../../product/util';
function CartItemOld({ item, deadline, deliveryDate }) {
    const dispatch = useDispatch();
    const { title, id, quantity, disabledForRegion, quantityPossible, supplier } = item;
    const orderType = useSelector((state) => state.newCheckout.orderType);
    const recipients = useSelector((state) => state.newCheckout.recipients);
    const quantityMultiplier = orderType === 'group' ? Math.max(recipients.length, 1) : 1;
    const soldOut = (quantityPossible && quantityPossible <= 0) || item.soldOut === '1';
    const tooMany = !soldOut && quantityPossible && quantityPossible < quantity * quantityMultiplier;
    const mainImage = (() => {
        if ('small' in item.navigationImage) {
            return item.navigationImage.small;
        }
        return item.navigationImage.fullSize;
    })();
    const deadlinePassed = (() => {
        if (supplier) {
            const supplierDeadline = moment(supplier.deadline, 'hh:mm:ss').isBefore(deadline)
                ? moment(supplier.deadline, 'hh:mm:ss')
                : deadline;
            return moment(deliveryDate).isSame(moment().add(1, 'days'), 'day')
                ? moment().isAfter(supplierDeadline)
                : false;
        }
    })();
    const totalPriceItem = item.quantity > 1 ? item.price * item.quantity : item.price;
    const urlName = kebabCase(title);
    const classes = classNames({
        'cart-item': true,
        'cart-item--disabled': disabledForRegion || soldOut || deadlinePassed,
        'cart-item--too-many': tooMany,
    });
    return (React.createElement("div", { className: classes },
        React.createElement("div", { className: "cart-item__image" },
            React.createElement("img", { src: mainImage, alt: title })),
        React.createElement("div", { className: "cart-item__text-container" },
            React.createElement("div", { className: "cart-item__text", onClick: () => {
                    dispatch(push(`/product/${id}/${urlName}`));
                } },
                React.createElement("span", { className: "cart-item__title" }, title)),
            React.createElement("div", { className: "cart-item__price-container" },
                React.createElement(CartCounter, { value: quantity, title: title, onIncrease: () => {
                        dispatch(addToCart(item, true, undefined));
                    }, onDecrease: () => {
                        dispatch(removeFromCart(item, true));
                    } }),
                React.createElement(Price, { className: "cart-item__price", amount: totalPriceItem })),
            disabledForRegion && (React.createElement("span", { className: "cart-item__note" }, "Varen kan ikke leveres til valgt adresse.")),
            !disabledForRegion && soldOut && (React.createElement("span", { className: "cart-item__note" },
                "Vi er dessverre utsolgt for denne varen til valgt leveringsdag. Fors\u00F8k en annen leveringsdag eller fjern varen.",
                ' ')),
            !disabledForRegion && tooMany && (React.createElement("span", { className: "cart-item__note" },
                "Vi har kun ",
                quantityPossible,
                " av denne varen p\u00E5 lager.")),
            !disabledForRegion && deadlinePassed && (React.createElement("span", { className: "cart-item__note" }, "Bestillingsfristen for varen har g\u00E5tt ut.")))));
}
function CartItemNew({ item, deadline, deliveryDate }) {
    var _a, _b;
    const dispatch = useDispatch();
    const { title, id, quantity, disabledForRegion, supplier, availability } = item;
    const orderType = useSelector((state) => state.newCheckout.orderType);
    const recipients = useSelector((state) => state.newCheckout.recipients);
    const quantityMultiplier = orderType === 'group' ? Math.max(recipients.length, 1) : 1;
    const itemsAvailable = availability.length == 1 ? availability[0].quantity : (_b = (_a = availability.find(a => a.date == deliveryDate)) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 0;
    const tooMany = quantity * quantityMultiplier > itemsAvailable && itemsAvailable > 0;
    const mainImage = (() => {
        if ('small' in item.navigationImage) {
            return item.navigationImage.small;
        }
        return item.navigationImage.fullSize;
    })();
    const deadlinePassed = (() => {
        if (supplier) {
            const supplierDeadline = moment(supplier.deadline, 'hh:mm:ss').isBefore(deadline)
                ? moment(supplier.deadline, 'hh:mm:ss')
                : deadline;
            return moment(deliveryDate).isSame(moment().add(1, 'days'), 'day')
                ? moment().isAfter(supplierDeadline)
                : false;
        }
    })();
    const totalPriceItem = item.quantity > 1 ? item.price * item.quantity : item.price;
    const soldOut = itemsAvailable == 0;
    const urlName = kebabCase(title);
    const classes = classNames({
        'cart-item': true,
        'cart-item--disabled': disabledForRegion || soldOut || deadlinePassed,
        'cart-item--too-many': tooMany,
    });
    return (React.createElement("div", { className: classes },
        React.createElement("div", { className: "cart-item__image" },
            React.createElement("img", { src: mainImage, alt: title })),
        React.createElement("div", { className: "cart-item__text-container" },
            React.createElement("div", { className: "cart-item__text", onClick: () => {
                    dispatch(push(`/product/${id}/${urlName}`));
                } },
                React.createElement("span", { className: "cart-item__title" }, title)),
            React.createElement("div", { className: "cart-item__price-container" },
                React.createElement(CartCounter, { value: quantity, title: title, onIncrease: () => {
                        dispatch(addToCart(item, true, undefined));
                    }, onDecrease: () => {
                        dispatch(removeFromCart(item, true));
                    } }),
                React.createElement(Price, { className: "cart-item__price", amount: totalPriceItem })),
            disabledForRegion && (React.createElement("span", { className: "cart-item__note" }, "Varen kan ikke leveres til valgt adresse.")),
            !disabledForRegion && soldOut && (React.createElement("span", { className: "cart-item__note" },
                "Vi er dessverre utsolgt for denne varen til valgt leveringsdag. Fors\u00F8k en annen leveringsdag eller fjern varen.",
                ' ')),
            !disabledForRegion && tooMany && (React.createElement("span", { className: "cart-item__note" },
                "Vi har kun ",
                itemsAvailable,
                " av denne varen p\u00E5 lager.")),
            !disabledForRegion && deadlinePassed && (React.createElement("span", { className: "cart-item__note" }, "Bestillingsfristen for varen har g\u00E5tt ut.")))));
}
export default function CartItem(props) {
    const features = useSelector((state) => state.features);
    if (isFeatureEnabled(features.enabled, FEATURES.STORE)) {
        if (hasAvailability(props.item)) {
            const item = props.item;
            return React.createElement(CartItemNew, Object.assign({}, props, { item: item }));
        }
    }
    return React.createElement(CartItemOld, Object.assign({}, props));
}
