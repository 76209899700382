import { EditorialProducts } from 'common';
import groq from 'groq';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import sanityClient from 'utilities/sanityClient';
import { isProductSoldOut } from 'product';
function DynamicEditorialProducts({ documentId, regionId }) {
    const [editorialProductsDocument, setEditorialProductsDocument] = useState();
    const [editorialProductsData, setEditorialProductsData] = useState(null);
    const allProducts = useSelector((state) => state.products.products);
    const stock = useSelector((state) => state.products.stock);
    useEffect(() => {
        const query = groq `*[_id == $documentId && $regionId in regionProducts.regions][0]{
            title,
            subtitle,
            mobileSubtitle,
            leadText,
            target,
            targetText,
            displayProducts,
            "productIds": regionProducts.region_${regionId}
        }`;
        let isSubscribed = true;
        sanityClient
            .fetch(query, { documentId, regionId })
            .then((document) => (isSubscribed ? setEditorialProductsDocument(document) : null))
            .catch(console.error);
        return () => {
            isSubscribed = false;
        };
    }, [documentId, regionId]);
    useEffect(() => {
        if (editorialProductsDocument == null || (allProducts === null || allProducts === void 0 ? void 0 : allProducts.length) == 0) {
            return;
        }
        const products = editorialProductsDocument.productIds
            .map((featuredProductId) => allProducts.find(({ id }) => id == featuredProductId))
            .filter((product) => product);
        const soldOutProducts = products.filter((product) => isProductSoldOut(stock, product));
        const availableProducts = products.filter((product) => !isProductSoldOut(stock, product));
        setEditorialProductsData({
            title: editorialProductsDocument.title,
            subtitle: editorialProductsDocument.subtitle,
            mobileSubtitle: editorialProductsDocument.mobileSubtitle,
            leadText: editorialProductsDocument.leadText,
            target: editorialProductsDocument.target,
            targetText: editorialProductsDocument.targetText,
            displayProducts: editorialProductsDocument.displayProducts || 5,
            products: [...availableProducts, ...soldOutProducts],
        });
    }, [editorialProductsDocument, allProducts]);
    if (editorialProductsData == null)
        return null;
    return React.createElement(EditorialProducts, Object.assign({}, editorialProductsData));
}
export default DynamicEditorialProducts;
